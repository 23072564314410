import React from 'react';
import './slide.scss'

const SlideFieldRedux = (props) => {
    const { input, meta: { touched, error }, label, className, id, disabled, max } = props;

    // Définit une valeur par défaut si input.value est vide ou indéfini
    const value = input.value || 0;

    return (
        <div className={`form-group ${className}`}>
            <label htmlFor={id || input.name}>{label}</label>
            <div className="styled-range-container">
                <input
                    type="range"
                    id={id || input.name}
                    {...input}
                    value={value} // Utilise la valeur par défaut
                    min="0"
                    max={max}
                    step="1"
                    disabled={disabled}
                    onChange={(e) => input.onChange(e.target.value)} // Met à jour Redux Form
                />
                <div className="range-value" style={{ left: `${(value / 60) * 100}%` }}>
                    {value}
                </div>
            </div>
            {touched && error && <small className="invalid-feedback">{error}</small>}
        </div>
    );
};

SlideFieldRedux.defaultProps = {
    meta: {
        touched: false,
        error: false
    },
    className: '',
    id: '',
    input: {
        value: 0, // Valeur par défaut
        onChange: () => {}
    }
};

export default SlideFieldRedux;
