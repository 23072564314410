import React, {Fragment} from 'react'
import moment from "moment";
import CallToAction from "../../../../Commun/Button/CallToAction/CallToAction";
import _ from "lodash";

const Expert = (props) => {
    const {
        expert,
        dateExpert,
        categorieSinCode,
        permissions,
        contractId,
        accidentId,
        reparateur,
        submitDeleteExpert,
        submitPostAddExpert
    } = props
    return (
        <div className={'bloc-info bloc-expert'}>
            <h2>Expert</h2>
            <div>
                {_.includes(permissions, 'update accident informations') &&
                    <>
                        {expert && expert.raisonSociale ?
                            <CallToAction
                                onClick={() => {
                                    if (window.confirm(`Etes-vous sûr de vouloir supprimer l'expert ?`)) submitDeleteExpert()
                                }}
                                action={'delete-expert'}
                                toolText={'Supprimer l\'expert'}
                            /> :
                            <CallToAction
                                onClick={submitPostAddExpert}
                                action={'edit-expert'}
                                toolText={'Ajouter l\'expert DEKRA Expertise'}
                            />
                        }
                    </>
                }
                <div className={'content row'}>
                    {(expert && expert.raisonSociale) ?
                        <Fragment>
                            <div className={'col-5 left-content'}>
                                <p>Expert missionné :</p>
                            </div>
                            <div className={'col-7 right-content'}>
                                <p>{expert.raisonSociale}</p>
                            </div>
                            <div className={'col-5 left-content'}>
                                <p>Numéro de référence :</p>
                            </div>
                            <div className={'col-7 right-content'}>
                                <p>{expert.reference}&nbsp;</p>
                            </div>
                            <div className={'col-5 left-content'}>
                                <p>Date de passage programmée au :</p>
                            </div>
                            <div className={'col-7 right-content'}>
                                <p>
                                    <br/>{dateExpert ? moment(dateExpert).format('dddd DD MMMM YYYY') : '-'}

                                    {_.includes(permissions, 'update accident informations') && reparateur && reparateur.id &&
                                        <CallToAction
                                            url={{
                                                pathname: `/contrat/${contractId}/sinistre/${accidentId}/modification-date-expert`,
                                                state: {
                                                    repairerId: reparateur.id
                                                }
                                            }}
                                            action={'edit-repairer'}
                                            toolText={'Modifier la date de passage'}
                                        />
                                    }
                                </p>
                            </div>
                            <div className={'col-5 left-content'}>
                                <p>Adresse postale :</p>
                            </div>
                            <div className={'col-7 right-content'}>
                                <p>{expert.rue1} {expert.rue2 !== '' ? `- ${expert.rue2}` : ''}</p>
                            </div>
                            <div className={'col-5 left-content'}>
                                <p>Code postal - Ville :</p>
                            </div>
                            <div className={'col-7 right-content'}>
                                <p>{expert.codePostal} {expert.ville}</p>
                            </div>
                            <div className={'col-5 left-content'}>
                                <p>Téléphone :</p>
                            </div>
                            <div className={'col-7 right-content'}>
                                <p>{expert.phone}</p>
                            </div>
                            <div className={'col-5 left-content'}>
                                <p>Email :</p>
                            </div>
                            <div className={'col-7 right-content'}>
                                <p>{expert.email}</p>
                            </div>
                            {
                                categorieSinCode !== '10' && expert.id !== "366432" &&
                                <>
                                    <div className={'col-5 left-content'}>
                                        <p>Lien vers l'extranet BCA :</p>
                                    </div>
                                    <div className={'col-7 right-content'}>
                                        <p><a
                                            href={`https://apps.bca.fr/extranet-front/assureur/details/${expert.reference}`}
                                            target={'_blank'} rel="noopener noreferrer">Accès BCA</a></p>
                                    </div>
                                </>
                            }
                            {
                                expert.id === '364935' &&
                                <>
                                    <div className={'col-5 left-content'}>
                                        <p>Lien vers l'extranet Expertise Concept :</p>
                                    </div>
                                    <div className={'col-7 right-content'}>
                                        <p><a
                                            href={`https://vroom.pro/occ/521323419/c1741df9ade5b555a5e7f3ac7e3f26f30af8e83fa901cc3127589c3721747923`}
                                            target={'_blank'} rel="noopener noreferrer">Accès extranet</a></p>
                                    </div>
                                </>
                            }
                            {
                                expert.id === '365283' &&
                                <>
                                    <div className={'col-5 left-content'}>
                                        <p>Lien vers l'extranet IDEA :</p>
                                    </div>
                                    <div className={'col-7 right-content'}>
                                        <p><a href={`https://assureur.idea-expertises.com/?t=8192FF7102843F08`}
                                              target={'_blank'} rel="noopener noreferrer">Accès extranet</a></p>
                                    </div>
                                </>
                            }
                        </Fragment>
                        : <div className={'col-12'}>
                            <p>Aucun expert n'a été missionné pour ce sinistre</p>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default Expert
