import React from 'react';
import moment from "moment/moment";

const BeneficiaireEffectifItem = (props) => {
    const {index, beneficiaire} = props
	
    return (
        <tr key={index}>
            <td>{beneficiaire.nom} {beneficiaire.prenom}</td>
            <td>{beneficiaire.nom_naissance}</td>
            <td>{moment(beneficiaire.naissance).format('L')}</td>
            <td>{beneficiaire.ville_naissance}</td>
            <td>{beneficiaire.pays_naissance}</td>
            <td>{beneficiaire.statut}</td>
        </tr>
    );
};

export default BeneficiaireEffectifItem;
