import React from 'react';
import _ from "lodash";
import BeneficiaireEffectifItemContainer from "./BeneficiaireEffectifItem/BeneficiaireEffectifItemContainer";
import {Link} from "react-router-dom";
const BeneficiairesEffectif = (props) => {
    const {beneficiaires_effectifs, roles, tiers} = props

    return (
        <div className={'bloc-info bloc-beneficiaires-effectif'}>
            <h2>Bénéficiaires effectifs et dirigeants</h2>

            {
                !_.isEmpty(beneficiaires_effectifs) ?
                <table className="table">
                    <thead>
                    <tr>
                        <th scope="col">Nom et Prénom</th>
                        <th scope="col">Nom de naissance</th>
                        <th scope="col">Date de naissance</th>
                        <th scope="col">Ville de naissance</th>
                        <th scope="col">Pays de naissance</th>
                        <th scope="col">Statut</th>
                        {!_.includes(roles, 'gestionnaire televente') ?
                            <>
                                <th scope="col">&nbsp;</th>
                                <th scope="col">&nbsp;</th>
                            </> : null
                        }
                    </tr>
                    </thead>
                    <tbody>
                    {beneficiaires_effectifs.map((b, index) =>
                        <BeneficiaireEffectifItemContainer key={index} beneficiaire={b} roles={roles} {...props}/>
                    )}
                    </tbody>
                </table>
                : <p className={'no-result'}>Il n'y a pas de bénéficiaire effectif sur le tiers</p>
            }
        </div>
    );
};

export default BeneficiairesEffectif;
