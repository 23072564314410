import React, { useState, useEffect } from 'react';
import DevisTableContainer from "./DevisTable/DevisTableContainer";
import moment from "moment";
import darkLoupe from "../../../../../assets/img/icons/loupe_dark.png";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import _ from "lodash";
import {useLocation} from "react-router-dom";

const DevisListe = (props) => {
    const { fetchQuotes, roles, query } = props;
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [searchField, setSearchField] = useState();
    const [searchFieldTemp, setSearchFieldTemp] = useState(); // Stocke la donnée le temp que l'utilisateur finisse de taper
    const [searchRealisateur, setSearchRealisateur] = useState();
    const [searchRealisateurTemp, setSearchRealisateurTemp] = useState(); // Stocke la donnée le temp que l'utilisateur finisse de taper
    const [apporteurName, setApporteurName] = useState();
    const [apporteurNameTemp, setApporteurNameTemp] = useState(); // Stocke la donnée le temp que l'utilisateur finisse de taper
    const [onlyOrange, setOnlyOrange] = useState(false);
    const [acteGestion, setActeGestion] = useState('');
    const [status, setStatus] = useState('');
    const [etat, setEtat] = useState('');
    const location = useLocation()

    useEffect(() => {

    }, [location.search]);

    const changeDate = (startDate, endDate) => {
        setStartDate(startDate);
        setEndDate(endDate);
    }

    const handleChangeFilterOrange = (e) => {
        const isChecked = e.target.checked;
        setOnlyOrange(isChecked);
    }

    const refreshQuotesWithoutNewFilters = () => {
        fetchQuotes({
            query,
            filters: {
                ...JSON.parse(localStorage.getItem('devisFilters'))
            }
        });
    }

    const resetFilters = () => {
        setStartDate(null);
        setEndDate(null);
        setSearchField('');
        setSearchFieldTemp('');
        setOnlyOrange(false);
        setActeGestion('');
        setStatus('');
        setEtat('');
        setSearchRealisateur('');
        setSearchRealisateurTemp('');
        setApporteurName('');
        setApporteurNameTemp('');
        localStorage.removeItem('devisFilters');

        fetchQuotes({ query, filters: {} });
    }

    useEffect(() => {
        const savedFilters = JSON.parse(localStorage.getItem('devisFilters'));
        if (savedFilters) {
            if (savedFilters.startDate) {
                setStartDate(moment(savedFilters.startDate));
            }
            if (savedFilters.endDate) {
                setEndDate(moment(savedFilters.endDate));
            }
            if (savedFilters.search) {
                setSearchField(savedFilters.search);
                setSearchFieldTemp(savedFilters.search);
            }
            if (savedFilters.realisateur) {
                setSearchRealisateur(savedFilters.realisateur);
                setSearchRealisateurTemp(savedFilters.realisateur);
            }
            if (savedFilters.apporteurName) {
                setApporteurName(savedFilters.apporteurName);
                setApporteurNameTemp(savedFilters.apporteurName);
            }
            if (savedFilters.acteGestion) {
                setActeGestion(savedFilters.acteGestion);
            }
            if (savedFilters.status) {
                setStatus(savedFilters.status);
            }
            if (savedFilters.etat) {
                setEtat(savedFilters.etat);
            }
            if (typeof savedFilters.onlyOrange !== 'undefined') {
                setOnlyOrange(savedFilters.onlyOrange);
            }
        }
    }, []);

    useEffect(() => {
        const tel = new URLSearchParams(location.search).get('tel')
        if(tel) {
            setSearchField(tel)
            setSearchFieldTemp(tel)
        }
        const filtersToSave = {
            start_date: startDate ? moment(startDate).format('YYYY-MM-DD') : '',
            end_date: endDate ? moment(endDate).format('YYYY-MM-DD') : '',
            search: tel || searchField || '',
            onlyOrange: onlyOrange,
            acteGestion: acteGestion,
            status: status,
            etat: etat,
            apporteurName: apporteurName || '',
            realisateur: searchRealisateur || '', // Include searchRealisateur here
        };
        localStorage.setItem('devisFilters', JSON.stringify(filtersToSave));

    }, [startDate, endDate, onlyOrange, acteGestion, status, apporteurName, searchRealisateur, etat, fetchQuotes, searchField, location.search]);

    useEffect(() => {
        fetchQuotes({
            query,
            filters: {
                ...JSON.parse(localStorage.getItem('devisFilters'))
            }
        });
    }, [startDate, endDate, onlyOrange, acteGestion, status, etat, fetchQuotes, location.search]);

    useEffect(() => {
        if (searchFieldTemp !== searchField) {
            const timeoutId = setTimeout(() => {
                setSearchField(searchFieldTemp);
            }, 240);
            return () => clearTimeout(timeoutId);
        } else {
            return () => { };
        }
    }, [searchFieldTemp]);

    useEffect(() => {
        if (searchRealisateurTemp !== searchRealisateur) {
            const timeoutId = setTimeout(() => {
                setSearchRealisateur(searchRealisateurTemp);
            }, 240);
            return () => clearTimeout(timeoutId);
        } else {
            return () => { };
        }
    }, [searchRealisateurTemp]);

    useEffect(() => {
        if (apporteurNameTemp !== apporteurName) {
            const timeoutId = setTimeout(() => {
                setApporteurName(apporteurNameTemp);
            }, 240);
            return () => clearTimeout(timeoutId);
        } else {
            return () => { };
        }
    }, [apporteurNameTemp]);

    return (
        <div className={'tasks-container container'}>
            <div className={'row'}>
                <div className={'col-12'}>
                    <h1 className={'title-primary title-task'}>Mes devis</h1>
                    {_.includes(roles, 'gestionnaire commercial') && <div className={'d-inline-block float-right'}>
                        <input
                            type="checkbox"
                            name="onlyOrange"
                            onChange={handleChangeFilterOrange}
                            checked={onlyOrange}
                        />
                        <label htmlFor="onlyOrange" className={'small ml-2'}>Afficher seulement les devis orange ?</label>
                    </div>}
                </div>
                <div className={'col-12 filters'}>
                    <div className={'content'}>
                        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="fr">
                            <DatePicker
                                className={'mr-2 small date-picker mt-1'}
                                label='Date de début'
                                value={startDate}
                                onChange={(newValue) => {
                                    changeDate(newValue, endDate);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>

                        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="fr">
                            <DatePicker
                                label='Date de fin'
                                className={'mr-2 small date-picker mt-1'}
                                value={endDate}
                                onChange={(newValue) => {
                                    changeDate(startDate, newValue);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                                minDate={startDate}
                            />
                        </LocalizationProvider>
                        <select
                            name="filter_status"
                            value={status}
                            onChange={(e) => {
                                setStatus(e.target.value);
                            }}
                            className={'small-select select-filter-tasks custom-select mt-1'}
                        >
                            <option value={''}>Statut du devis</option>
                            <option value={'in_progress'}>En cours</option>
                            <option value={'cancelled'}>Perdu</option>
                            <option value={'won'}>Transformé</option>
                        </select>
                        <select
                            name="filter_actegestion"
                            value={acteGestion}
                            onChange={(e) => {
                                setActeGestion(e.target.value);
                            }}
                            className={'small-select select-filter-tasks custom-select mt-1'}
                        >
                            <option value={''}>Acte de gestion</option>
                            <option value={'AN'}>Affaire nouvelle</option>
                            <option value={'AV'}>Avenant</option>
                        </select>
                        <select
                            name="filter_etat"
                            value={etat}
                            onChange={(e) => {
                                setEtat(e.target.value);
                            }}
                            className={'small-select select-filter-tasks custom-select mt-1'}
                        >
                            <option value={''}>Etat du devis</option>
                            <option value={'1'}>Devis</option>
                            <option value={'2'}>Contrats en attente de paiement immédiat</option>
                            <option value={'3'}>Contrat payé</option>
                            <option value={'4'}>Contrat paiement différé</option>
                            <option value={'5'}>Expiré</option>
                        </select>
                        <div className={'d-inline-block mr-4'}>
                            <div className={'d-flex'}>
                                <input
                                    name="filter_realisateur"
                                    value={searchRealisateurTemp}
                                    onChange={e => setSearchRealisateurTemp(e.target.value)}
                                    className={'filters small-input with-icon select-filter-tasks'}
                                    type="text"
                                    style={{width: 250}}
                                    placeholder="Rechercher par réalisateur"
                                    onKeyDown={e => {
                                        if (e.key === 'Enter') {
                                            e.target.value !== searchRealisateur ? setSearchRealisateur(e.target.value) : refreshQuotesWithoutNewFilters()
                                        }
                                    }}
                                />
                                <span
                                    //le onchange met déjà à jour le réalisateur et la recherche
                                    onClick={() => refreshQuotesWithoutNewFilters()}
                                    className={'icon-small-input'}
                                >
                                    <img src={darkLoupe} alt="Loupe"/>
                                </span>
                            </div>
                        </div>
                        <div className={'d-inline-block mr-4'}>
                            <div className={'d-flex'}>
                                <input
                                    name="filter_apporteurName"
                                    value={apporteurNameTemp}
                                    onChange={e => {
                                        setApporteurNameTemp(e.target.value)
                                    }}
                                    onKeyDown={e => {
                                        if (e.key === 'Enter') {
                                            e.target.value !== searchField ? setApporteurName(e.target.value) : refreshQuotesWithoutNewFilters()
                                        }
                                    }}
                                    className={'filters small-input with-icon select-filter-tasks'}
                                    type="text"
                                    style={{width: 250}}
                                    placeholder="Rechercher par nom d'apporteur"
                                />
                                <span
                                    //le onchange met déjà à jour le réalisateur et la recherche
                                    onClick={() => refreshQuotesWithoutNewFilters()}
                                    className={'icon-small-input'}
                                >
                                    <img src={darkLoupe} alt="Loupe"/>
                                </span>
                            </div>
                        </div>
                        <div className={'d-inline-block'}>
                            <div className={'d-flex'}>
                                <input
                                    name="filter_devis"
                                    value={searchFieldTemp}
                                    onChange={e => {
                                        setSearchFieldTemp(e.target.value)
                                    }}
                                    onKeyDown={e => {
                                        if (e.key === 'Enter') {
                                            e.target.value !== searchField ? setSearchField(e.target.value) : refreshQuotesWithoutNewFilters()
                                        }
                                    }}
                                    className={'filters small-input with-icon select-filter-tasks'}
                                    type="text"
                                    style={{width: 250}}
                                    placeholder="Rechercher par ID devis, nom, numéro de téléphone"
                                />
                                <span
                                    //le onchange met déjà à jour le réalisateur et la recherche
                                    onClick={() => refreshQuotesWithoutNewFilters()}
                                    className={'icon-small-input'}
                                >
                                    <img src={darkLoupe} alt="Loupe"/>
                                </span>
                            </div>
                        </div>
                        <div>
                            <small onClick={() => resetFilters()}>Réinitialiser les filtres</small>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'row'}>
                <div className={'col'}>
                    <DevisTableContainer fetchQuotes={fetchQuotes}
                                         filters={JSON.parse(localStorage.getItem('devisFilters'))} className='loader'/>
                </div>
            </div>
        </div>
    );
};

export default DevisListe;
