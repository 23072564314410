import React, {Component, Fragment} from 'react';
import {Field} from "redux-form";
import MyButton from "../../../../../Commun/Button/Button";
import Spinner from "../../../../../Commun/Spinner/Spinner";
import SelectFieldRedux from "../../../../../Commun/Input/Select/SelectFieldRedux";
import {motifResil, motifResilVAE} from "../../../../../../utils/optionsSelect";
import DatePickerFieldRedux from "../../../../../Commun/Input/DatePicker/DatePickerFieldRedux";
import CheckboxFieldRedux from "../../../../../Commun/Input/Checkbox/CheckboxFieldRedux";
import TextAreaFieldRedux from "../../../../../Commun/Input/Text/TextAreaFieldRedux";
import moment from "moment";
import RadioFieldRedux from "../../../../../Commun/Input/Radio/RadioFieldRedux";
import APIFrame from "../../../../../Commun/APILink/APIFrame";
import {normalizeBooleanForNoobBelair} from "../../../../../../utils/normalizeInputs";
import _ from "lodash";

class Resiliation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            motif: this.props.motif,
        };
    }

    handleChange = (e) => {
        this.setState({motif: e.target.value})
        switch (e.target.value) {
            case 'X':
            case '9':
                this.props.changeValue('date', moment(this.props.taskDetail.contract.situation.date))
                break;
            case 'Z':
                this.props.changeValue('date', moment(this.props.taskDetail.contract.situation.date).add(60, 'days'))
                break;
            case 'B':
            case 'H':
                this.props.changeValue('date', moment(this.props.taskDetail.contract.situation.dateEcheance).subtract(1, 'days'))
                break;
            case '2':
                if (this.props.taskDetail.sinistre) {
                    if (this.props.taskDetail.sinistre.has_garantie_vol) {
                        this.props.changeValue('date', moment(this.props.taskDetail.sinistre.dateSurv).add(30, 'days'))
                    } else {
                        this.props.changeValue('date', moment(this.props.taskDetail.sinistre.dateSurv).add(1, 'days'))
                    }
                } else {
                    this.props.changeValue('date', moment())
                }
                break;
            case '4':
                if (this.props.taskDetail.sinistre) {
                    this.props.changeValue('date', moment(this.props.taskDetail.sinistre.dateSurv).add(1, 'days'))
                } else {
                    this.props.changeValue('date', moment())
                }
                break;
            case 'K':
            case 'F':
                this.props.changeValue('date', moment().add(10, 'days'))
                break;
            case 'C':
                this.props.changeValue('date', moment().add(30, 'days'))
                break;
            default:
                this.props.changeValue('date', moment())
                break;
        }
    }

    handleChangeCheckbox = (e) => {
        if (e.target.name === 'refuse') {
            this.props.unsetValue('reassign')
            this.props.unsetValue('transfer_ownership_missing')
        } else if (e.target.name === 'reassign') {
            this.props.unsetValue('refuse')
            this.props.unsetValue('transfer_ownership_missing')
        } else if (e.target.name === 'transfer_ownership_missing') {
            this.props.unsetValue('refuse')
            this.props.unsetValue('reassign')
        }
    }

    render() {
        const {
            handleSubmit,
            taskDetail,
            loadingChangeSituation,
            reassign,
            refuse,
            errorResil,
            errorTerminate,
            files,
            insurers,
            transfer_ownership_missing,
            motif_details
        } = this.props
        const {motif} = this.state
        const listeMotifs = taskDetail.contract.vehicule.codeProduit && taskDetail.contract.vehicule.codeProduit === '7311_VAE' ? motifResilVAE : motifResil;
        const hasMotifDetail = motif_details && _.some(motif_details, (value) => value && value === 'T');

        return (
            <div className="row">
                {files &&
                    <div className={'col-8'} style={{marginBottom: 30}}>
                        {files.map((file, index) =>
                            <APIFrame src={`${file.url}`} title={'Resiliation'} frameBorder="0"
                                    style={{height: '400px', width: '100%', marginBottom: 30}} key={index}/>
                        )}
                    </div>
                }
                {taskDetail.paniere === 'RES_AN' &&
                    <div className={'offset-md-3 col-6 alert alert-warning'}>
                        Merci de vérifier l'ensemble des pièces reçues ainsi que la date d'envoi de la LR pour omission
                        avant d'effectuer la résiliation.
                    </div>
                }
                {taskDetail.paniere === 'SURV' &&
                    <div className={'offset-md-3 col-6 alert alert-warning'}>
                        Merci d'appeler le client ou le courtier.
                        Merci de résilier le client à échéance suite à la surveillance du portefeuille.
                        Une fois la résiliation effectuée, il faut éditer la LR et le Relevé d'Informations avant de les
                        déposer sur Néopost.
                    </div>
                }

                {taskDetail.paniere === 'SAUV_CLI' &&
                    <div className={'offset-md-3 col-6 alert alert-warning'}>
                        Merci de vérifier l'intégralité du dossier et des pièces reçues.<br/>
                        Passer en cours le contrat si possible.<br/>
                        Sinon procéder à la résiliation du contrat pour omission.
                    </div>
                }
                {taskDetail.contract.vehicule.codeProduit === 'WAKAM_LIV' &&
                    <div className={'offset-md-3 col-6 alert alert-warning'}>
                        Le véhicule doit être sorti de la flotte. Utiliser le module de sortie de véhicule
                    </div>
                }
                <div className={`${files ? 'col-4' : 'offset-md-3 col-6'}`}>

                    {(taskDetail.paniere === 'RES_ESP' && taskDetail?.description) &&
                        <div className={'form-content mb-4'}>
                            <span dangerouslySetInnerHTML={{__html: taskDetail.description}}/>
                        </div>
                    }

                    <form className={`form-content`} onSubmit={handleSubmit}>
                        {(taskDetail.contract.situation.statutCode === '1' || taskDetail.contract.situation.statutCode === '2' || taskDetail.contract.situation.statutCode === '5' || taskDetail.contract.statut === 'Résiliée') ?
                            <Fragment>
                                {(!reassign && !refuse && !transfer_ownership_missing) &&
                                    <Fragment>
                                        <Field
                                            name='motif'
                                            component={SelectFieldRedux}
                                            label='Sélectionnez un motif de résiliation'
                                            onChange={this.handleChange}
                                        >
                                            <option value={null}>Sélectionner un motif</option>
                                            {listeMotifs.map((motif, index) => (
                                                <option key={index} value={motif.code}>{motif.libelle}</option>
                                            ))}
                                        </Field>
                                        {motif === '0' &&
                                            <Field
                                                name='insurer'
                                                component={SelectFieldRedux}
                                                label='Sélectionnez la compagnie'
                                            >
                                                <option value={null}>Sélectionner la compagnie</option>
                                                {insurers.map((insurer, index) => (
                                                    <option key={index} value={insurer.code}>{insurer.libelle}</option>
                                                ))}
                                            </Field>
                                        }
                                        {motif === 'Z' && <p className={'alert alert-danger'}>
                                            La LR50 a été générée
                                            le {moment(taskDetail.contract.situation.dateAffaireNouvelle).add(50, 'days').format('dddd DD MMMM YYYY')}.
                                            Merci de bien vérifier la date d'envoi.
                                        </p>}
                                        {motif === 'B' && <p className={'alert alert-danger'}>
                                            L'échéance du contrat est
                                            au {moment(taskDetail.contract.situation.dateEcheance).format('L')}. Merci
                                            de bien vérifier l'année.
                                        </p>}

                                        {motif === 'W' &&
                                            <Field
                                                name={`suite_vol_ou_vente`}
                                                component={RadioFieldRedux}
                                                data={[
                                                    {value: "vente", label: "Suite vente ou dépôt de vente"},
                                                    {value: "vol", label: "Suite vol"}
                                                ]}
                                            />
                                        }
                                        {motif &&
                                            <Field
                                                name='date'
                                                component={DatePickerFieldRedux}
                                                label={'Date de la résiliation'}
                                                minDate={moment(taskDetail.contract.situation.dateAffaireNouvelle)}
                                            />
                                        }
                                        {motif && (motif === "K" || motif === "F" || motif === "C") &&
                                            <div>
                                                <p className={'alert alert-warning mb-3'}>Attention à ne pas dépasser
                                                    les <b>4&nbsp;retours</b> à la ligne dans la description</p>
                                                <Field
                                                    name={'motif_description'}
                                                    component={TextAreaFieldRedux}
                                                    label={'Description du motif'}
                                                    subLabel={'350 caractères maximum'}
                                                    maxLength={'350'}
                                                />
                                            </div>
                                        }
                                        {motif && (motif === "Z") &&
                                            <div>
                                                <Field
                                                    name={`motif_details.manque_cg`}
                                                    component={CheckboxFieldRedux}
                                                    type={'checkbox'}
                                                    label={`Manque Carte grise`}
                                                    normalize={normalizeBooleanForNoobBelair}
                                                />
                                                <Field
                                                    name={`motif_details.manque_antecedents_bonus`}
                                                    component={CheckboxFieldRedux}
                                                    type={'checkbox'}
                                                    label={`Manques antécédents ou bonus`}
                                                    normalize={normalizeBooleanForNoobBelair}
                                                />
                                                <Field
                                                    name={`motif_details.manque_coordonnees_bancaires`}
                                                    component={CheckboxFieldRedux}
                                                    type={'checkbox'}
                                                    label={`Manque coordonnées bancaires (RIB, SEPA) ou règlement`}
                                                    normalize={normalizeBooleanForNoobBelair}
                                                />
                                                <Field
                                                    name={`motif_details.manque_permis`}
                                                    component={CheckboxFieldRedux}
                                                    type={'checkbox'}
                                                    label={`Manque permis`}
                                                    normalize={normalizeBooleanForNoobBelair}
                                                />
                                                <Field
                                                    name={`motif_details.manque_cni`}
                                                    component={CheckboxFieldRedux}
                                                    type={'checkbox'}
                                                    label={`Manque CNI/titre de séjour...`}
                                                    normalize={normalizeBooleanForNoobBelair}
                                                />
                                                <Field
                                                    name={`motif_details.manque_documents`}
                                                    component={CheckboxFieldRedux}
                                                    type={'checkbox'}
                                                    label={`Manque l'ensemble des documents`}
                                                    normalize={normalizeBooleanForNoobBelair}
                                                />
                                                {!hasMotifDetail &&
                                                    <p className={'alert alert-danger'}>
                                                        Merci de sélectionner au moins un motif de résiliation
                                                    </p>
                                                }
                                            </div>
                                        }
                                    </Fragment>
                                }
                            </Fragment>
                            : <div className={'alert alert-warning'}>
                                <p>Vous ne pouvez pas résilier ce contrat car il est en statut
                                    : {taskDetail.contract.situation.statut}</p>
                            </div>
                        }

                        {
                            (taskDetail.paniere !== 'SAUV_CLI' && (!motif || motif !== "Z")) && <>
                                <Field
                                    name={'reassign'}
                                    component={CheckboxFieldRedux}
                                    label={'Je ne peux pas traiter la demande de résiliation'}
                                    onChange={this.handleChangeCheckbox}
                                />
                                <Field
                                    name={'refuse'}
                                    component={CheckboxFieldRedux}
                                    label={'Le motif de resiliation n\'est pas valable'}
                                    onChange={this.handleChangeCheckbox}
                                />
                                <Field
                                    name={'transfer_ownership_missing'}
                                    component={CheckboxFieldRedux}
                                    label={'Il manque le justificatif de cession'}
                                    onChange={this.handleChangeCheckbox}
                                />
                            </>
                        }

                        {reassign &&
                            <Fragment>
                                <Field
                                    name={'commentaire'}
                                    component={TextAreaFieldRedux}
                                    label={'Commentaire'}
                                />
                            </Fragment>
                        }
                        {refuse &&
                            <p className={'alert alert-warning'}>
                                Le motif de résiliation n'est pas valable, un mail de refus va être envoyé au client.
                            </p>
                        }
                        {transfer_ownership_missing &&
                            <p className={'alert alert-warning'}>
                                Il manque le justificatif de cession, un mail va être envoyé au client.
                            </p>
                        }
                        <MyButton
                            variant='raised'
                            color='secondary'
                            size='large'
                            className={'btn btn-primary'}
                            disabled={loadingChangeSituation || (motif && motif === 'Z' && !hasMotifDetail)}
                        >
                            {loadingChangeSituation && <Spinner absolute/>}
                            Valider
                        </MyButton>
                        {(errorResil || errorTerminate) &&
                            <h2 className={'title-primary text-uppercase'}>Une erreur est survenue</h2>}
                    </form>
                </div>
            </div>
        );
    };
}

export default Resiliation;
