import {Field} from "redux-form";
import TextFieldRedux from "../../../../Commun/Input/Text/TextFieldRedux";
import MyButton from "../../../../Commun/Button/Button";
import Spinner from "../../../../Commun/Spinner/Spinner";
import SelectFieldRedux from "../../../../Commun/Input/Select/SelectFieldRedux";

const optionsStrate = [
    { label: 'Top Agt' },
    { label: 'Challenger' },
    { label: 'Relai' },
    { label: 'Concess 10 AFN et plus' },
    { label: 'Concess moins de 10 AFN' },
    { label: 'Pépite' },
    { label: 'Pilier' },
    { label: 'Champion' },
    { label: 'Plateforme' },
    { label: 'Nouveau' },
    { label: 'Nouveau Agt' },
    { label: 'Occasionnel' },
    { label: 'Occasionnel Agt' },
    { label: 'Perdu' },
    { label: 'Perdu Agt' },
    { label: 'AMA' },
    { label: 'Réseau direct' },
    { label: 'Non qualifié' },
];


const ChangeInfoApporteur = (props) => {
    const {loading, handleSubmit, tiers} = props

    return (
        <div className={'contract-container container'}>
            <div className={'row return-content'}>
                <div className={'col'}>
                    <button onClick={() => props.history.go(-1)} className={'btn btn-return'}>Retour</button>
                </div>
            </div>
            <div className={'row'}>
                <div className={'col'}>
                    <h1 className={'title-primary'}>{tiers.belair_id} - {tiers.titre} {tiers.name}</h1>
                </div>
            </div>
            <div className={'row header-row'}>
                <div className={'col-12'}>
                    <form className={'form-content'} onSubmit={handleSubmit}>
                        <Field
                            name='strate'
                            component={SelectFieldRedux}
                            label={'Strate'}
                        >
                            <option value=''>Séléctionner une Strate</option>
                            {optionsStrate.map((strate) => <option value={strate.label} key={strate.label}>{strate.label}</option>)}
                        </Field>
                        <Field
                            name='reseau_commercial'
                            component={TextFieldRedux}
                            label={'Réseau commercial'}
                        />
                        <MyButton
                            variant='raised'
                            color='secondary'
                            size='large'
                            className={'btn btn-primary'}
                            disabled={loading}
                        >
                            {loading && <Spinner absolute/>}
                            Modifier les informations
                        </MyButton>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ChangeInfoApporteur
