import _ from 'lodash';
import moment from 'moment';
import {Field} from 'redux-form';
import {canalArrivee, casUsage, interlocuteurSelect, reponseFid, TypeDeSuivi} from '../../../../../utils/optionsFid';
import CheckboxFieldRedux from '../../../../Commun/Input/Checkbox/CheckboxFieldRedux';
import DatePickerFieldRedux from '../../../../Commun/Input/DatePicker/DatePickerFieldRedux';
import RadioFieldRedux from '../../../../Commun/Input/Radio/RadioFieldRedux';
import SelectFieldRedux from '../../../../Commun/Input/Select/SelectFieldRedux';
import TextAreaFieldRedux from '../../../../Commun/Input/Text/TextAreaFieldRedux';
import TextFieldRedux from '../../../../Commun/Input/Text/TextFieldRedux';
import Spinner from '../../../../Commun/Spinner/Spinner';
import {Link} from "react-router-dom";
import {redirectInNewWindow} from "../../../../../utils/function";
import {openInWindow} from "../../../../../constants/constants";
import FileFieldRedux from "../../../../Commun/Input/File/FileFieldRedux";
import React, {useState} from "react";
import APILink from "../../../../Commun/APILink/APILink";
import Badge from "../../../../Commun/Badge/Badge";
import SlideFieldRedux from "../../../../Commun/Input/Slide/Slide";

const leviers = [
    { name: 'Argumentaire', code: 'O' },
    { name: 'Régularisation de Prime', code: 'G' },
    { name: 'Avenant de fidélisation', code: 'H' },
    { name: 'Remise commerciale', code: 'I' },
    { name: 'Cartes cadeaux', code: 'J' },
];

export default function CreateFidelisation({errorForm ,contract, postGetTarifContract, tarif, loading_tarif, loadingForm, handleSubmit, roles, edit = null, fidelisation, formValues: { gestes, ...formValues } = {}, changeValue,}) {
    const readOnly = !_.includes(roles, 'gestionnaire fidelisation') || fidelisation?.ouvert === 'F';
    const { type, interlocuteur, cas_usage } = formValues
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);

    const handleClickTarifContract = () => {
        if (!readOnly) {
            postGetTarifContract();
        }
    };

    const sanitizeCasUsage = (casUsage) => {
        if (!casUsage) return '';

        const sanitized = casUsage.replace(/^[^:]*:\s*/, '').replace(/\s+/g, '_');
        return sanitized;
    };

    const getPDFPath = (type, interlocuteur, casUsage = null, eligible = true, reponse = null) => {
        const basePath = '/documents/fidelisation';

        if (!type || !interlocuteur) {
            console.warn("Missing parameters for generating PDF path.");
            return null;
        }

        // Prioritize 'Répondeur' check above all else
        if (reponse === "Répondeur" && type === "Appel sortant") {
            return `${basePath}/${type.replace(/\s+/g, '_')}/${interlocuteur}/repondeur.pdf`;
        }

        // Check for 'Avis d'échéance' and 'En cours d'année' cases
        const isAvisEcheance = casUsage === "Révision tarifaire : avis d'échéance" || casUsage === "Révision tarifaire : en cours d'année";
        const folder = eligible ? `${type.replace(/\s+/g, '_')}` : "Non_éligible";

        // Determine sanitized file name based on other conditions
        const sanitizedCasUsage = isAvisEcheance
            ? 'avis_echeance'
            : eligible
                ? sanitizeCasUsage(casUsage)
                : 'non_eligible';

        const pdfPath = `${basePath}/${folder}/${interlocuteur}/${sanitizedCasUsage.toLowerCase()}.pdf`;

        return pdfPath;
    };

    const handleDownloadPDF = () => {
        const eligible = contract.avenant_link_fid;
        const { type, interlocuteur, cas_usage, reponse } = formValues;

        // Use getPDFPath with the reponse value
        return getPDFPath(type, interlocuteur, cas_usage, eligible, reponse);
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        handleSubmit();
        setIsFormSubmitted(true);
    };

    const canDisplayButton = ((contract.avenant_link_fid && type && interlocuteur && cas_usage && cas_usage !== 'Autres') || (!contract.avenant_link_fid && type && interlocuteur));

    return (
        <div className="contract-container container">
            <div className={'row return-content'}>
                <div className={'col'}>
                    <button onClick={() => this.props.history.go(-1)} className={'btn btn-return'}>Retour</button>
                </div>
            </div>
            <div className={'row'}>
                <div className={'col-12'}>
                    <h1 className={'title-primary'}>{contract.souscripteur.titre} {contract.souscripteur.nom} {contract.souscripteur.id} - Demande de fidélisation
                        du {edit ? moment(fidelisation.date_demande).format('L') : moment().format('L')}</h1>
                </div>
                <div className={'col-12'}>
                        <span className={'bold text-uppercase subtitle'}>Contrat n°{contract.numeroPolice} - {contract.title}</span>
                </div>
            </div>

            <div className="row header-row">
                <div className="col-12">
                    {isFormSubmitted && !errorForm && !loadingForm ? (
                            <div className='row'>
                                <div className='col-12'>
                                    <div className='alert alert-success'>
                                        La demande de fidélisation a bien été créée.
                                    </div>
                                </div>
                            </div>
                        ) :
                        <form className="form-content" onSubmit={readOnly ? undefined : handleFormSubmit}>
                            <div className="row ">
                                <div className="col-3">
                                </div>
                                <div className="col-6">
                                    <Field
                                        name={'date_demande'}
                                        component={DatePickerFieldRedux}
                                        label={'Date de la demande'}
                                        disabled={readOnly}
                                        minDate={moment().subtract(1, 'Y')}
                                        maxDate={moment().add(1, 'Y')}
                                    />
                                </div>
                            </div>
                            <div className={'row header-row'}>
                                <div className={'col-12'}>
                                    <div className={`alert alert-info text-center row`}>
                                        <p className={'col-5 align-items-center pt-1'}>
                                            L'assuré est éligible à la fidélisation :{' '}
                                            {contract.avenant_link_fid ? 'OUI' : <span className="error">NON</span>}
                                        </p>
                                        {contract.situation.statutCode === '1' &&
                                            contract.vehicule.codeProduit !== '7311_VAE' &&
                                            contract.vehicule.codeProduit !== '7311_NVEI' && (
                                                <p className={'text-right col-7 align-top'}>
                                                    {tarif?.message && (
                                                        <p className={'text-danger text-center'}>{tarif.message}</p>
                                                    )}
                                                    {tarif?.FractionnementChoisi === 'M' && (
                                                        <>
                                                            <p>
                                                                <b>{tarif?.PrimeAnnuelleFracMensuel}€/an</b> soit{' '}
                                                                <b>{tarif?.PrimeMensuellePromo}€/mois</b>
                                                            </p>
                                                            <p>
                                                                <b>
                                                                    {tarif?.MontantMensuelRegul.replace('-', '')}€/mois
                                                                </b>{' '}
                                                                de différence soit une{' '}
                                                                {tarif?.RatioRegul.includes('-')
                                                                    ? 'diminution'
                                                                    : 'augmentation'}{' '}
                                                                de <b>{tarif?.RatioRegul.replace('-', '')}%</b>
                                                            </p>
                                                        </>
                                                    )}
                                                    {tarif?.FractionnementChoisi === 'A' && (
                                                        <>
                                                            <p>
                                                                <b>{tarif?.PrimeAnnuelleFracAnnuel}€/an</b>
                                                            </p>
                                                            <p>
                                                                <b>{tarif?.MontantAnnuelRegul.replace('-', '')}€</b> de
                                                                différence soit une{' '}
                                                                {tarif?.RatioRegul.includes('-')
                                                                    ? 'diminution'
                                                                    : 'augmentation'}{' '}
                                                                de <b>{tarif?.RatioRegul.replace('-', '')}%</b>
                                                            </p>
                                                        </>
                                                    )}
                                                    {loading_tarif ? <Spinner/> :
                                                        <span
                                                            className={'btn btn-primary btn-small'}
                                                            onClick={handleClickTarifContract}
                                                            style={{
                                                                margin: 0,
                                                                pointerEvents: readOnly ? 'none' : 'auto'
                                                            }}
                                                        >Recalculer la prime</span>
                                                    }
                                                </p>
                                            )}
                                    </div>
                                </div>
                            </div>
                            <div className="row header-row">
                                <div className="col-6">
                                    <Field
                                        name="type"
                                        component={SelectFieldRedux}
                                        label="Type de suivi"
                                        required
                                        disabled={readOnly}
                                    >
                                        <option value="">Choisissez le type de suivi</option>
                                        {TypeDeSuivi.map((t, index) => (
                                            <option key={index} value={t.libelle}>
                                                {t.libelle}
                                            </option>
                                        ))}
                                        {edit && <option value={'Validation mail'}>Validation mail</option>}
                                    </Field>
                                </div>
                                <div className="col-6">
                                    <Field
                                        name="interlocuteur"
                                        component={SelectFieldRedux}
                                        label="Interlocuteur"
                                        required
                                        disabled={readOnly}
                                    >
                                        <option value="">Choisissez l'interlocuteur</option>
                                        {interlocuteurSelect.map((t, index) => (
                                            <option key={index} value={t.libelle}>{t.libelle}</option>
                                        ))}
                                    </Field>
                                </div>
                            </div>
                            <div className="row header-row">
                                <div className="col-6">
                                    {formValues.type === "Appel sortant" && <Field
                                        name="canal"
                                        component={SelectFieldRedux}
                                        label={"Canal d'arrivée"}
                                        required
                                        disabled={readOnly || edit}
                                    >
                                        <option value="">Choisissez le canal</option>
                                        {canalArrivee.map((t, index) => (
                                            <option key={index} value={t.libelle}>{t.libelle}</option>
                                        ))}
                                    </Field>}

                                </div>
                                <div className="col-6">
                                    <Field
                                        name="cas_usage"
                                        component={SelectFieldRedux}
                                        label={"Cas d'usage"}
                                        required
                                        disabled={readOnly || edit}
                                    >
                                        <option value="">Choisissez le cas d'usage</option>
                                        {casUsage.map((t, index) => (
                                            <option key={index} value={t.libelle}>{t.libelle}</option>
                                        ))}
                                    </Field>
                                </div>
                            </div>
                            {canDisplayButton &&
                                <div className="row header-row">
                                    <div className="col-12 text-center">
                                        <hr/>
                                        <p className="d-inline">Découvrez la fiche de discours du cas client :</p>
                                        <a href={handleDownloadPDF()} target={'_blank'}
                                           className={'btn btn-cta h-auto mt-3 my-0 mx-2 py-2 d-inline'}>
                                            Télécharger le script
                                        </a>
                                        <hr/>
                                    </div>
                                </div>
                            }
                            <div className="row header-row">
                                <div className="col-6">
                                    <Field name="description"
                                           component={TextAreaFieldRedux}
                                           label="Description"
                                           required rows={4}
                                           subLabel={'500 caractères maximum'}
                                           maxLength={'500'}
                                           disabled={readOnly}
                                    />
                                </div>

                                <div className="col-6">
                                    <Field label="Devis concurrentiel" name='devis_concurrentiel'
                                           component={FileFieldRedux} onChangeValue={changeValue}/>
                                    {fidelisation?.devis_concurrentiel_url && <APILink
                                        href={fidelisation.devis_concurrentiel_url}
                                        target={'_blank'}
                                        rel="noopener noreferrer"
                                        className={'btn btn-primary btn-small'}
                                    >
                                        <i className="fas fa-file-pdf"/> Télécharger le devis concurrentiel
                                    </APILink>}
                                </div>
                            </div>

                            {(contract.avenant_link_fid || (edit && fidelisation.gestes.length > 0)) && (
                                <>
                                    <div className="row  header-row">
                                        <h2 className="title-primary col-12 mb-2">Leviers</h2>
                                        {leviers.map((geste) => {
                                            const existingAvenant = fidelisation?.gestes?.find((g) => g.type === geste.code && g.avenant);

                                            if (geste.code === 'J' && contract.apporteur1 !== '43397') {
                                                return null;
                                            }

                                            if (geste.code === 'I' && !contract.is_eligible_remise_commercial && !fidelisation?.ouvert === 'T') {
                                                return null;
                                            }

                                            return (
                                                <div className="col-4 mb-4" key={geste.code}>
                                                    <div className="border p-4 rounded">
                                                        <Field
                                                            className={`fidelisation fid-${geste.code}`}
                                                            component={CheckboxFieldRedux}
                                                            name={`gestes.${geste.code}`}
                                                            label={geste.name}
                                                            disabled={readOnly}
                                                            onChange={(e) => {
                                                                if (geste.code === 'O') {
                                                                    changeValue(`gestes_attributes.${geste.code}.argumentaire`, e.target.checked ? 'T' : 'F');
                                                                }
                                                                changeValue(`gestes.${geste.code}`, e.target.checked);

                                                                if (geste.code === 'J' && e.target.checked) {
                                                                    changeValue(`gestes_attributes.${geste.code}.montant_carte`, "30")
                                                                }
                                                            }}
                                                        />
                                                        {gestes && gestes[geste.code] && (
                                                            <>
                                                                {geste.code === 'J' && (
                                                                    <Field
                                                                        name={`gestes_attributes.${geste.code}.montant_carte`}
                                                                        component={SelectFieldRedux}
                                                                        label={"Choisir le montant de la carte"}
                                                                        disabled={readOnly}
                                                                    >
                                                                        <option value="30">30€</option>
                                                                        <option value="40">40€</option>
                                                                    </Field>
                                                                )}
                                                                {(geste.code === 'G' || geste.code === 'H') && (
                                                                    <>
                                                                        {existingAvenant ? (
                                                                            <Link
                                                                                className="btn btn-secondary btn-small h-auto mb-3 d-block text-center"
                                                                                onClick={(e) => redirectInNewWindow({
                                                                                    e,
                                                                                    url: `/devis/${existingAvenant.avenant}`,
                                                                                    name: openInWindow.devis
                                                                                })}
                                                                            >
                                                                                Modifier le devis
                                                                            </Link>
                                                                        ) : (
                                                                            <a href={geste.code === 'G' ? contract.avenant_link_regul : contract.avenant_link_fid}
                                                                               target="_blank" rel="noopener noreferrer"
                                                                               className="btn btn-secondary btn-small h-auto mb-3 d-block text-center">
                                                                                Faire le devis
                                                                            </a>
                                                                        )}
                                                                        <hr/>
                                                                        <Field
                                                                            type="number"
                                                                            component={TextFieldRedux}
                                                                            name={`gestes_attributes.${geste.code}.avenant`}
                                                                            label="Numero de devis de l'avenant"
                                                                            disabled={readOnly}
                                                                        />
                                                                    </>
                                                                )}
                                                                {geste.code === 'I' && (
                                                                    <>
                                                                        {Math.floor(contract.net_impaye) <= 0 &&
                                                                            <div className={'alert alert-info m-0 p-2 mb-2'}>
                                                                                <p>Le client n'a pas d'impayé sur sa
                                                                                    quittance en cours</p>
                                                                            </div>
                                                                        }
                                                                        <Field
                                                                            name={`gestes_attributes.${geste.code}.montant_remise`}
                                                                            component={SlideFieldRedux}
                                                                            max={contract.net_impaye < 60 ? Math.floor(contract.net_impaye) : 60}
                                                                            label={"Choisir le montant de la remise"}
                                                                            disabled={readOnly}
                                                                        />
                                                                    </>
                                                                )}
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </>
                            )}

                            {(contract.avenant_link_fid || (edit && fidelisation.gestes.length > 0) ) &&
                                <div className="row header-row">
                                    <div className="col-6">
                                        <Field
                                            name="reponse"
                                            component={SelectFieldRedux}
                                            label={'Nature de la réponse'}
                                            disabled={readOnly}
                                        >
                                            <option value="">Choisissez la réponse</option>
                                            {reponseFid.map((t, index) => (
                                                <option key={index} value={t.libelle}>
                                                    {t.libelle}
                                                </option>
                                            ))}
                                            {formValues.type === 'Appel sortant' &&
                                                <option value="Répondeur">Répondeur</option>}
                                        </Field>
                                    </div>
                                </div>
                            }

                            {formValues.reponse !== "Acceptation" &&
                                <>
                                    <div className="row header-row">
                                        <div className="col-6">
                                            <Field
                                                name="accept_rdv"
                                                component={RadioFieldRedux}
                                                label="Acceptation du RDV"
                                                data={[
                                                    {value: 'T', label: 'Oui'},
                                                    {value: 'F', label: 'Non'},
                                                ]}
                                                disabled={readOnly}
                                            />
                                        </div>
                                        <div className="col-6">
                                            {formValues.accept_rdv === 'T' && (
                                                <Field
                                                    name={'date_rdv'}
                                                    component={DatePickerFieldRedux}
                                                    label={'Programmer un agenda FID'}
                                                    minDate={moment()}
                                                    maxDate={moment().add(1, 'Y')}
                                                    disabled={readOnly}
                                                    required={formValues.accept_rdv === 'T'}
                                                />
                                            )}
                                        </div>
                                    </div>

                                    {formValues.accept_rdv === 'T' &&
                                        <div className="row header-row">
                                            <div className="col-6">
                                                <Field name="desc_rdv"
                                                       component={TextAreaFieldRedux}
                                                       label="Description de l'agenda"
                                                       required rows={4}
                                                       subLabel={'255 caractères maximum'}
                                                       maxLength={'255'}
                                                       disabled={readOnly}
                                                />
                                            </div>
                                        </div>
                                    }
                                </>
                            }
                            {!readOnly && (
                                <div className="row header-row text-center">
                                    {loadingForm ? <Spinner absolute/> : <button
                                        className={'btn btn-primary position-relative header-row col-6 offset-3'}>
                                        Valider
                                    </button>}
                                </div>
                            )}
                        </form>
                    }
                </div>
            </div>
        </div>
    );
}
